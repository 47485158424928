import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { useNextStep, useNotification, useSharedVariables } from 'hooks';
import {
	ChooseOption,
	InputForm,
	InvestmentNotAllowed,
	RadioSelect,
	SelectedItemComponentForAccreditationState,
	UploadDocuments,
} from '../../components';
import { Header } from '../../components/header';
import {
	Accred,
	AccredQueAndAns,
	AccredSelectedKeyState,
	FinraUploadState,
	SelectedUploadOptionKeyState,
	useAccreditationRequests,
	useAccreditationSignatoryRequest,
} from '../../stores';
import { BodyWrapper, LabelElement } from 'components';
import { nonOfAboveLabel } from 'views/accreditation/constants';

export const Accreditation506c = () => {
	const [selectedItems, setSelectedItems] = useState<any>();
	const [remount, setRemount] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const [selectedkey, setSelectedKey] = useRecoilState(AccredSelectedKeyState);
	const resetSelectedKey = useResetRecoilState(AccredSelectedKeyState);
	const [selectedAllLabels, setAllLabels] = useRecoilState(AccredQueAndAns);
	const resetSetSelectedAllLabel = useResetRecoilState(AccredQueAndAns);
	const [finraUploadValue, setFinraUploadValue] =
		useRecoilState(FinraUploadState);
	const allList = useRecoilValue(Accred);
	const setSelectedUploadKey = useSetRecoilState(SelectedUploadOptionKeyState);
	const setSelectedItemComponent = useSetRecoilState(
		SelectedItemComponentForAccreditationState
	);

	const { handleNext: goToNextStep, sessionPayloadDetail } = useNextStep();
	const {
		submitFindraAccreditation,
		submitUploadDocuments,
		startAccreditation506c,
		isHideNonOfAbove,
	} = useAccreditationRequests();
	const { successNotification } = useNotification();
	const { onboardingType } = useSharedVariables();
	const {
		submitSignatoryAccreditation,
		nextDisabled,
		labelNext,
		isSignatoryType,
		isNonOfAbowNotAllowed,
		organizationName,
		handleInvestmentNotAllowedOnClose,
	} = useAccreditationSignatoryRequest();

	const { label } =
		(sessionPayloadDetail as any).currentAction?.metadata?.accreditationType ??
		{};

	const {
		settings,
		title,
		subTitle,
		label: optionLabel,
		back = 'selectOption',
		type,
	} = useMemo(() => allList[selectedkey] ?? {}, [allList, selectedkey]);

	const filteredSettings = useMemo(
		() =>
			isHideNonOfAbove
				? settings.filter(
						(setting: { label: string }) => setting.label !== nonOfAboveLabel
					)
				: settings,
		[isHideNonOfAbove, settings]
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setSelectedItemComponent(selectedItems), [selectedItems]);

	useEffect(() => {
		if (onboardingType !== 'complex') {
			startAccreditation506c();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (event: any) => {
		setSelectedItems(event);
	};

	//passing item as we will call this function in choose option
	const handleNext = useCallback(
		async (item?: any) => {
			if (isSignatoryType) {
				await submitSignatoryAccreditation(selectedItems?.label, optionLabel);
				return;
			}
			setAllLabels((prev: any) => {
				const prevData = JSON.parse(JSON.stringify(prev));
				const index = prevData.findIndex(
					(item: any) => item.key === selectedkey
				);
				if (index !== -1) {
					prevData.splice(index, 1, {
						key: selectedkey,
						answers: item?.label ?? selectedItems?.label,
						questions: title ?? '',
					});
				} else {
					prevData.push({
						key: selectedkey,
						answers: item?.label ?? selectedItems?.label,
						questions: title ?? '',
					});
				}
				return [...prevData];
			});
			//need to remount focefully to select component
			setRemount(false);
			setTimeout(() => setRemount(true), 0);
			const id = item?.id || selectedItems?.id || '';
			const key = item?.key || selectedItems?.key || '';
			setSelectedKey(key);
			setSelectedUploadKey(`${id}_${key}`);
		},
		[
			setAllLabels,
			setSelectedKey,
			title,
			submitSignatoryAccreditation,
			isSignatoryType,
			selectedItems,
			setSelectedUploadKey,
			selectedkey,
			optionLabel,
		]
	);

	const handleBack = useCallback(() => {
		setAllLabels((prev: any) => {
			const prevData = JSON.parse(JSON.stringify(prev));
			const filteredData =
				prevData?.map((item: { key: string }) =>
					item.key === back ? { ...item, isDeleted: true } : item
				) ?? [];
			return [...filteredData];
		});
		setSelectedKey(back);
		//need to remount focefully to select component
		setRemount(false);
		setTimeout(() => setRemount(true), 0);
	}, [back, setAllLabels, setSelectedKey]);

	//get default value if selected value is not present then return first index of array else retrun previous selected object of Array
	const getDefaultValue = useMemo(() => {
		const index = selectedAllLabels?.find(
			(item: any) => item?.key === selectedkey
		);
		const data = settings?.find((item: any) => item?.label === index?.answers);
		if (data) return data;
		else return settings[0];
	}, [selectedAllLabels, selectedkey, settings]);

	useEffect(() => {
		//setting up default selected Value when setting will change
		setSelectedItems(getDefaultValue);
	}, [getDefaultValue, settings]);

	const handleChooseOption = useCallback(
		(event: any) => {
			resetSetSelectedAllLabel();
			onChange(event);
			handleNext(event);
		},
		[handleNext, resetSetSelectedAllLabel]
	);

	const handleChange = useCallback(
		(e: any) => {
			const { name, value } = e.target ?? {};
			setFinraUploadValue({ ...finraUploadValue, [name]: value });
		},
		[finraUploadValue, setFinraUploadValue]
	);

	const onNextInputForm = useCallback(async () => {
		await submitFindraAccreditation();
		goToNextStep();
	}, [submitFindraAccreditation, goToNextStep]);

	const handleUploadDocuments = useCallback(async () => {
		setIsLoading(true);
		const status = await submitUploadDocuments();
		setIsLoading(!status);
		if (status === true) {
			resetSelectedKey();
			goToNextStep();
			successNotification('File Uploaded.');
		}
	}, [
		goToNextStep,
		resetSelectedKey,
		submitUploadDocuments,
		successNotification,
	]);

	const getValue = useCallback((input: string) => {
		if (input && input.trim() !== '') return true;
		else return false;
	}, []);

	const getDisable = useMemo(() => {
		const { crdNumber } = finraUploadValue ?? {};
		if (selectedkey === 'finraUploadDocuments') {
			if (getValue(crdNumber) && isChecked) return false;
			return true;
		}
		return false;
	}, [finraUploadValue, getValue, selectedkey, isChecked]);

	const renderMainComponent = useMemo(() => {
		switch (type) {
			case 'choose-option':
				return (
					<ChooseOption settings={settings} handleClick={handleChooseOption} />
				);
			case 'radio-select':
				return (
					remount && (
						<RadioSelect
							defaultValue={getDefaultValue}
							onChange={onChange}
							settings={filteredSettings}
							handleNext={handleNext}
							handleBack={handleBack}
							isNextDisabled={nextDisabled(selectedItems)}
							labelNext={labelNext}
						/>
					)
				);
			case 'input-form':
				return (
					<InputForm
						settings={settings}
						handleNext={onNextInputForm}
						handleBack={handleBack}
						handleChange={handleChange}
						isDisabled={getDisable}
						setChecked={setIsChecked}
					/>
				);
			case 'upload-document':
				return isNonOfAbowNotAllowed(selectedkey) ? (
					<InvestmentNotAllowed
						handleBack={handleBack}
						handleClose={handleInvestmentNotAllowedOnClose}
						organizationName={organizationName}
					/>
				) : (
					<UploadDocuments
						handleBack={handleBack}
						handleNext={handleUploadDocuments}
						isLoading={isLoading}
					/>
				);
			default:
				return <div></div>;
		}
	}, [
		type,
		settings,
		handleChooseOption,
		remount,
		getDefaultValue,
		handleNext,
		handleBack,
		nextDisabled,
		selectedItems,
		onNextInputForm,
		handleChange,
		getDisable,
		handleUploadDocuments,
		labelNext,
		isLoading,
		isNonOfAbowNotAllowed,
		selectedkey,
		organizationName,
		handleInvestmentNotAllowedOnClose,
		filteredSettings,
	]);

	const labelElement = useMemo(
		() => <LabelElement text={label ?? 'Accreditation'} />,
		[label]
	);

	const renderHeader = useMemo(
		() =>
			isNonOfAbowNotAllowed(selectedkey) ? (
				<></>
			) : (
				<Header title={title} subTitle={subTitle} />
			),
		[isNonOfAbowNotAllowed, selectedkey, subTitle, title]
	);

	return (
		<Fragment>
			<BodyWrapper
				label={labelElement}
				headerElement={renderHeader}
				bodyElement={<>{renderMainComponent}</>}
			/>
		</Fragment>
	);
};
