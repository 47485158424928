import { atom } from 'recoil';

export const FundBankDetailsState = atom<any>({
	key: 'bank-details',
	default: {},
});

export const IsInvestingAmountEmptyState = atom<boolean>({
	key: 'is-investing-amount-empty-state-key',
	default: false,
});
