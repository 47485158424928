import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { isShowSkipState } from 'states/kyc';

import {
	CurrentActionIndex,
	CurrentStepIndex,
	FundNameState,
	SessionDetailsState,
	SessionPayloadDetailState,
	SignAgreementProviderState,
} from './stores';
import { KYCTokenState } from 'views/kyc/stores/states';
import { SignAgreementUrlState } from 'views/sign-agreement/stores/states';
import { AccrdCustom506bUrlState } from 'views/accreditation/stores/states';
import { useSharedVariables } from 'hooks/shared-variable';
import { ProofReadDocumentState } from 'views/proof-read-document/stores';

export const useNextStep = () => {
	//creating as a global state as it will be called in different component so
	//local state create different instance so to prevent it we are creating global
	//state so that it will have single instance
	const [isInvestedAmount, setIsInvestedAmount] = useState(true);
	const [currentStep, setCurrentStep] = useRecoilState(CurrentStepIndex);
	const [currentAction, setCurrentAction] = useRecoilState(CurrentActionIndex);
	const setFundNameState = useSetRecoilState(FundNameState);
	const [sessionDetails, setSessionDetails] =
		useRecoilState(SessionDetailsState);
	const [sessionPayloadDetail, setSessionPayloadDetail] = useRecoilState(
		SessionPayloadDetailState
	);
	const setAccrdCustom506bUrlState = useSetRecoilState(AccrdCustom506bUrlState);
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const setKycToken = useSetRecoilState(KYCTokenState);
	const setCurrentUrl = useSetRecoilState(SignAgreementUrlState);
	const [provider, setProvider] = useRecoilState(SignAgreementProviderState);
	const setProofReadDocData = useSetRecoilState(ProofReadDocumentState);

	const { onboardingType } = useSharedVariables();

	const handleNext = (updatedSession: any = {}) => {
		const sessionList = updatedSession?.steps ? updatedSession : sessionDetails;
		if (currentAction < sessionList?.steps[currentStep]?.actions.length - 1) {
			setCurrentAction(currentAction + 1);
		} else if (currentStep < sessionList?.steps?.length - 1) {
			setCurrentStep(currentStep + 1);
			setCurrentAction(0);
		}
		setIsShowSkip(false);
	};
	/**
	 * here we are conditionally calculating the value of currentAction and stepId*/
	const getStepDetails = useMemo(() => {
		const { steps = [], nodes = [] } = sessionDetails ?? {};
		let currentActionObj = null;
		let stepId = '';
		const conditions: any = [];
		if (steps || nodes) {
			/**
			 * if the flow is complex onboarding*/
			if (onboardingType === 'complex') {
				const { iframe, key, label, status, value } =
					nodes?.actions?.[0] ?? nodes?.actions ?? {};
				const complexCurrentAction: any = {
					iframe: iframe ?? false,
					key: key ?? '',
					label: label ?? '',
					status: status ?? '',
					value: value ?? [],
					_id: nodes?._id ?? '',
				};
				/**
				 * if the flow is complex onboarding and the the step id is authentication*/
				if (nodes?.stepId === 'authentication' && steps?.length === 1) {
					currentActionObj = steps[currentStep]?.actions[currentAction] ?? '';
					stepId = steps[currentStep]?.stepId ?? '';
				} else if (nodes?.stepId === 'fundInvestment') {
					const metadata = { ...nodes?.actions?.[0]?.metadata };
					complexCurrentAction.metadata = metadata;
					complexCurrentAction.token = nodes?.requestUrl?.token ?? '';
					currentActionObj = complexCurrentAction;
					stepId = nodes?.stepId ?? '';
				} else if (nodes?.stepId === 'accreditation') {
					const metadata = { ...nodes?.actions?.[0].metadata };
					complexCurrentAction.metadata = metadata;
					complexCurrentAction.currentUrl = nodes?.requestUrl ?? '';
					complexCurrentAction.iframe = true;
					currentActionObj = complexCurrentAction;
					stepId = nodes?.stepId ?? '';
				} else if (nodes?.stepId === 'form' || nodes?.stepId === 'kybForm') {
					const metadata = { ...nodes?.actions?.[0]?.metadata };
					complexCurrentAction.metadata = metadata;
					currentActionObj = complexCurrentAction;
					stepId = nodes?.stepId ?? '';
				} else if (nodes?.stepId === 'kyc') {
					const metadata = { ...nodes?.actions?.[0]?.metadata };
					complexCurrentAction.metadata = metadata;
					currentActionObj = complexCurrentAction;
					stepId = nodes?.stepId ?? '';
				} else if (nodes?.stepId === 'successScreen') {
					const metadata = { ...nodes?.actions?.[0]?.metadata };
					complexCurrentAction.metadata = metadata;
					currentActionObj = complexCurrentAction;
					stepId = nodes?.stepId ?? '';
				} else {
					currentActionObj = complexCurrentAction;
					stepId = nodes?.stepId ?? '';
				}
			} else {
				/**
				 * if the flow is inline onboarding
				 * */
				currentActionObj = steps[currentStep]?.actions[currentAction] ?? '';
				stepId = steps[currentStep]?.stepId ?? '';
			}
		}
		return { currentActionObj, stepId, conditions };
	}, [currentAction, currentStep, onboardingType, sessionDetails]);

	useEffect(() => {
		const { steps, fundName, nodes, ...restSessionDetails }: any =
			sessionDetails ?? {};
		if (fundName) {
			setFundNameState(fundName);
		}
		// here we are create state only for saving requesturl for 506b for custom upload
		if (sessionDetails && (steps || nodes)) {
			if (
				nodes?.actions?.[0]?.metadata?.accreditationDocument?.value ===
					'customUpload' &&
				onboardingType === 'complex'
			) {
				setAccrdCustom506bUrlState(nodes.requestUrl);
			}
			setSessionPayloadDetail({
				...restSessionDetails,
				stepsId: getStepDetails?.stepId ?? '',
				currentAction: {
					...(getStepDetails?.currentActionObj ?? ''),
					// Shahbaaz:sometime _id not getting set in getStepDetails that why I pull from sessionDetails
					_id: sessionDetails?.nodes?._id ?? '',
				},
				conditions: getStepDetails?.conditions ?? [],
			});

			if (nodes?.stepId === 'kyc' && onboardingType === 'complex') {
				const { requestUrl } = nodes ?? {};
				setKycToken({ ...requestUrl });
			}
			if (nodes?.stepId === 'signAgreement' && onboardingType === 'complex') {
				const { requestUrl, agreements, pipelineActions } = nodes ?? {};
				setCurrentUrl({ index: 0, url: requestUrl?.url });
				const provider =
					agreements?.[0]?.provider ??
					pipelineActions?.[0]?.metadata?.signAgreementProvider;
				setProvider(provider);
			}
			if (nodes?.stepId === 'proofReading' && onboardingType === 'complex') {
				// set proof read data for complex flow
				setProofReadDocData(nodes?.actions[0].metadata.proofDocUrls);
			} else if (steps[currentStep]?.stepId === 'proofReading') {
				// set proof read data for linear flow
				setProofReadDocData(
					steps[currentStep]?.actions[currentAction].metadata
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentAction,
		currentStep,
		setSessionPayloadDetail,
		getStepDetails,
		sessionDetails,
		setFundNameState,
		setAccrdCustom506bUrlState,
		setKycToken,
		setCurrentUrl,
	]);

	const agreementProvider = useMemo(() => {
		return provider;
	}, [provider]);

	return {
		currentStep,
		setSessionDetails,
		handleNext,
		sessionPayloadDetail,
		sessionDetails,
		agreementProvider,
		setProvider,
		isInvestedAmount,
		setIsInvestedAmount,
		setSessionPayloadDetail,
	};
};
